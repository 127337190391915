@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('react-micro-modal/dist/index.css');

html,
body,
#__next {
  height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

html {
  background-color: #000000;
}

.with-radial-bg-gradient {
  position: relative;
}

.with-radial-bg-gradient::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  @apply bg-new-radial-gradient;
}

.html-gradient {
  background-image: linear-gradient(
    180deg,
    #000000 0%,
    #160033 51.56%,
    #120128 100%
  );
  background-repeat: no-repeat;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media not all and (min-resolution: 0.001dpcm) {
  img[loading='lazy'] {
    clip-path: inset(0.5px);
  }
}

.rounded-safari-fix {
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}

/* fix clipping "g", "y" nails  */
.bg-text-gradient {
  position: relative;
  margin-bottom: -0.1em;
  padding-bottom: 0.1em;
}

@layer components {
  .dialect-wallet-button,
  .wallet-adapter-button.dialect-wallet-button {
    @apply py-2.5 px-4 rounded-[38px] bg-button transition-[background-position] duration-200 inline-flex items-center space-x-2 flex-nowrap whitespace-nowrap text-white;
  }

  .dialect-wallet-button:hover,
  .dialect-wallet-button:not([disabled]):hover,
  .wallet-adapter-button.dialect-wallet-button:not([disabled]):hover {
    @apply bg-button-hover;
  }

  .btn-primary {
    @apply min-h-[3rem] text-white py-2.5 px-4 rounded-[38px] bg-button hover:bg-button-hover transition-[background-position] duration-200 inline-flex items-center space-x-2 flex-nowrap whitespace-nowrap;
  }

  .dialect-new-button,
  .wallet-adapter-button.dialect-new-button {
    @apply py-3 px-4 rounded-[12px] bg-new-button transition-[background-image] duration-200 inline-flex items-center space-x-2 justify-center items-center flex-nowrap whitespace-nowrap text-[15px] leading-5 font-medium text-white;
  }

  .dialect-new-button:hover,
  .dialect-new-button:not([disabled]):hover,
  .wallet-adapter-button.dialect-new-button:not([disabled]):hover {
    @apply bg-new-button-hover;
  }

  .dialect-new-button--secondary,
  .wallet-adapter-button.dialect-new-button--secondary {
    @apply bg-dark-700 bg-none transition-[background-color];
  }

  .dialect-new-button--secondary:hover,
  .dialect-new-button--secondary:not([disabled]):hover,
  .wallet-adapter-button.dialect-new-button--secondary:not([disabled]):hover {
    @apply bg-dark-600 bg-none;
  }

  .dialect-secondary-button {
    @apply px-5 py-[17px] bg-[rgba(255,255,255,0.06)] hover:bg-[rgba(255,255,255,0.12)] font-medium rounded-2xl leading-[120%] border-t border-t-[rgba(255,255,255,0.08)] transition-[background-color];
  }
}

ol.list-indented {
  padding-left: 1em;
}

ol.list-legal-lower-latin {
  list-style-type: none;
  counter-reset: alpha;
}

ol.list-legal-lower-latin li {
  list-style: none;
  position: relative;
}

ol.list-legal-lower-latin li:before {
  counter-increment: alpha;
  content: '(' counter(alpha, lower-alpha) ') ';
}
